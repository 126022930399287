.students .students__info,
.content_formatter {
  h1:not(:first-child), h2:not(:first-child) {
    padding-top: 15px;
  }

  ul, ol {
    padding-top:15px;
    font-size: 18px;
      line-height: 1.4;
  }

  li {
    color: #797979;
  }

  p:not(:first-child) {
    padding-top: 15px;
  }

h1,h2{
       font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      color: #000000;
      margin-bottom: 15px;
    }
    p{
      text-align: left;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.4;
      color: #000000;

    }

    ul {
       list-style-type: disc;
       list-style-position: inside;
    }
    ol {
       list-style-type: decimal;
       list-style-position: inside;
    }
    ul ul, ol ul {
       list-style-type: circle;
       list-style-position: inside;
       margin-left: 15px;
    }
    ol ol, ul ol {
       list-style-type: lower-latin;
       list-style-position: inside;
       margin-left: 15px;
    }

    li {
      padding:5px 0px;
    }
}

.students{
  margin: 70px 0 40px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  &:after{
    content:"";
    background-image: url('/src/img/svg/Vector5.svg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: -50px;
    max-width: 350px;
    width: 100%;
    height: 250px;
    z-index: -15;
  }
  .students__fon{
    position: absolute;
    top: -70px;
    left: -59px;
    z-index: -1
  }
  .students__info{
    max-width: 1245px;
    width: 100%;


    .students__text__cirk {
      list-style-type: disc;
      list-style-position: inside;
      margin: 15px 0;
      li{
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin: 5px 0;
        color: #000000;
      }
    }
    .students__text + .students__text{
      margin-top: 15px;
    }
    .students__details{
      display: none;
      background: linear-gradient(269.42deg, #F48C1D 0%, #F1B904 100%);
      border-radius: 30px;
      width: 178px;
      height: 35px;
      margin: 15px auto 0;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;

    }
  }
}



@media (max-width: 1599.98px){
  .students {
    .students__fon {
      position: absolute;
      top: -60px;
      left: -10px;
      z-index: -1;
    }
    .students__info {
      max-width: 1200px;

    }
  }

}

@media (max-width: 1499.98px){

  .students{
    &:after {
      right: 0;
      max-width: 320px;
      height: 240px;
    }
    .students__info {
      max-width: 1140px;
    }
  }
  .about__students {
    .students__info {
      padding-right: 70px;
    }
  }
}
@media (max-width: 1399.98px){
.students {
  .students__info{
    max-width: 1050px;
    .students__text {
      font-size: 17px;
    }
  }
}
}
@media (max-width: 1299.98px){

.students {
  .students__info{
    max-width: 1000px;
    .students__text {
      font-size: 16px;
    }
  }
}

}
@media (max-width: 1169.98px){

  .students {
    margin: 50px 0 40px;
    .students__fon {
      top: -60px;
      left: 0;
    }
  }
  .about__students {
    .students__info {
      padding-right: 0;
    }
  }

}

@media (max-width: 991.98px){
  .about-women-fon{
    display: none;
  }
}
@media (max-width: 767.98px){
  .students {
    .students__fon {
      top: -40px;
      left: 0;
    }
  }

}
@media (max-width: 575.98px){
  .students{
    .students__info{
      .students__title{
      font-size: 17px;
    }
      .students__text{
        font-size: 16px;
        line-height: 1.3;
      }
      .students__text__cirk {
      margin: 15px 0;
      li{
        font-size: 16px;
      }
    }
      .students__details{
        display: block;
      }
    }
    &:after{
      display: none;
    }
  }
}