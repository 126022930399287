.main-title{
  margin-top: 40px;
  .main-title__text{
    position: relative;
    padding-left: 30px;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.3;
    color: #313131;

    &:after{
      content:"";
      background-image: url('/src/img/svg/Vector.svg');
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -22px;
      left: 0;
      width: 98px;
      height: 97px;
      z-index: -1;
    }
  }
}

@media (max-width: 991.98px){
  .main-title {
    .main-title__text {
      font-size: 32px;
    }
  }
}



@media (max-width: 767.98px){
  .main-title {
    .main-title__text {
      font-size: 28px;
    }
  }
}



@media (max-width: 575.98px){
  .main-title {
    margin-top: 25px;
    .main-title__text {
      font-size: 18px;
      padding-left: 0;
      &:after{
        top: -15px;
        left: -15px;
        width: 69px;
        height: 69px;
      }
    }
  }
}