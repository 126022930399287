.pages__cart{
  max-width: 1000px;
  width: 100%;

  .pages__info{
    display: flex;
    justify-content: center;
    .pages__name{
      font-weight: bold;
      font-size: 36px;
      line-height: 1.3;
      color: #313131;
      position: relative;
      display: block;
      &:after{
        content:"";
        background-image: url('/src/img/svg/Vector2.svg');
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: -19px;
        left: -49px;
        width: 97px;
        height: 87px;
        z-index: -1;
      }
    }
  }
}
  .pages__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 30px 0 30px;
  }
    .pages__box{
      &--full {
      flex-basis: 100%;
    max-width: 210px;
    min-width: 210px;
      }
      width: 210px;
      // margin: 20px 45px 20px 15px;
      margin: 20px 15px 20px 15px;
      height: auto;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
        border-radius:30px;
      min-height: 300px;
      overflow: hidden;
      img {
        border-radius:30px;
      }
      &:hover {
        .pages__href {
          z-index: 0;
        }
      }
      .pages__href{
        width: 100%;
        position: relative;
        transition: 0.2s ease;
        height: 100%;
        z-index: -1;
        position: absolute;
        .pages__box__click{
          width: 100%;
          background: linear-gradient(269.93deg, #F48C1D 0%, #F1B904 100%);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
          border-radius: 30px;
          padding-top: 15px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .pages__box__text{
            font-weight: normal;
            font-size: 17px;
            line-height: 1.3;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5px;
            padding: 0 35px;
          }
          .pages__box__bold{
            font-weight: 700;
            margin: 8px 0;
            font-size: 17px;
            line-height: 1.3;
            color: #FFFFFF;
            text-align: center;
            margin-top: 5px;
            padding: 0 35px;
          }
         
          .pages__box__number{
            font-weight: bold;
            font-size: 24px;
            line-height: 1.1;
            display: block;
            text-align: center;
            color: #FFFFFF;
          }
          .pages__box__name{
            font-weight: normal;
            font-size: 20px;
            line-height: 1.1;
            color: #FFFFFF;
            display: block;
            text-align: center;
            margin-top: 0px;
          }
          .pages__box__btn{
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #F5C139;
            border-radius: 50px 50px 30px 30px;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            margin-top: 10px;
            position: absolute;
            bottom: 0;
          }
        }
        &:after{
          display: none;
          content: "";
          background-image: url('/src/img/photo/ximik.jpg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s;
          z-index: 1;
          border-radius: 10px;
        }
      }
      &:hover .pages__href:after{
        transition: 0.5s;
        display: none;
      }
     

    }


.cart__big{
  max-width: 100%;
  .pages__content{
  }
}
.cart__bitwin{
  .pages__content{
    justify-content: space-between;
  }
}
.tasks__cart__img{
  width: 100%;
}

@media (max-width: 1599.98px){
 .pages__content{
    .pages__box {
      // margin: 20px 12px;
      // margin: 20px 45px 20px 15px;
      margin: 20px 12px;
      // &:nth-child(3n+3) {
      //   margin: 20px 15px 20px 15px;
      // }
    }
  }
}

@media (max-width: 1499.98px){

  .pages__cart {
    max-width: 940px;
    .pages__content {
      margin: 25px 0 0;
    }
  }
  .cart__big{
    max-width: 100%;
  }
}

@media (max-width: 1399.98px){

  .pages__cart {
    max-width: 840px;
    .pages__content {
      margin: 25px 0 0;
      .pages__box {
        .pages__box__click{
          .pages__box__text{
            margin-top: 0;
          }
        }
      }
    }
  }
  .cart__big{
    max-width: 100%;
  }
}

@media (max-width: 1299.98px){

  .pages__cart{
    max-width: 840px;
  }
  .cart__big{
    max-width: 100%;
  }
}

@media (max-width: 1169.98px){

      .pages__cart {
        margin: 0 auto;
        .pages__info{
          .pages__name {
            font-size: 32px;
          }
        }
        .pages__content {
          // justify-content: center;
          justify-content: flex-start;
        }
      }
.cart__bitwin{
  .pages__content{
    margin: 0 auto;
    justify-content: center;
    .pages__box {
      margin: 20px 10px;
    }
  }
}

}
@media (max-width: 1024.98px){

}

@media (max-width: 991.98px){
.cart__bitwin{
  .pages__content{
    // max-width: 710px;
    max-width: 75%;
    justify-content: center;
    margin: 0 auto;
    .pages__box {
      margin: 20px 13px;
    }
  }
}
 
}
@media (max-width: 767.98px){
  .pages__cart{
    margin-top: 25px;
    .pages__info{
      .pages__name {
        font-size: 25px;
        text-align: center;
      }
    }
    .pages__content {
      margin: 10px 0 0;
      
    }
  }
}
@media (max-width: 575.98px){
  .pages__cart{
    .pages__content {
      .pages__box {
        margin: 20px 5px;
        width: 206px;
      }
    }
  }
}

.pages__cart .pages__content {
  @media only screen and (max-width: 992px) {
    justify-content: flex-start;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  @media only screen and (max-width: 568px) {
    flex-direction: column;
    align-items: center;
  }
  &--medium {
    @media only screen and (max-width: 992px) {
      justify-content: center;
      margin: 0 auto;
    }
  }
}

// .pages__content .pages__box:nth-child(3n+3) {
//   @media only screen and (max-width: 992px) {
//     margin: 20px 45px 20px 15px;
//   }
//   @media only screen and (max-width: 568px) {
//     margin: 20px 5px;
//   }
// }

.pages__cart {
  .pages__content {
    &--center {
      justify-content: center;
      @media only screen and (max-width: 1169.98px) {
        justify-content: center;
      }
      @media only screen and (max-width: 1024px) {
        max-width: 75%;
        margin: 0 auto;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}