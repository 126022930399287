
.header {
	width: 100%;
	background: linear-gradient(267.13deg, #F48C1D 0%, #F1B904 100%);
	border-radius: 0px 0px 30px 30px;
	height: 65px;
	display: flex;
	z-index: 9999;
	.header-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.header-menu {
      .open-menu {
        display: none;
        width: 30px;
        height: 24px;
        position: relative;

        .open-menu__line {
          position: absolute;
          top: 0;
          width: 100%;
          height: 4px;
          background-color: rgb(255, 255, 255);
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s;
        }
      }

      .close-menu {
        display: none;
        width: 25px;
        height: 25px;
        position: absolute;
        left: 30px;
        .open-menu__line {
          height: 3px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          background-color: #313131;
          z-index: 99999;
        }

        .open-menu__line:first-child {
          display: none;
        }

        .open-menu__line:last-child {
          display: none;
        }

        .open-menu__line:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg)
        }

        .open-menu__line:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg)
        }
      }

      .nav-main {
        .nav-main__list {
          display: flex;
          align-items: center;

          .nav-main__item {
            width: 151px;
            padding: 4px 0;
            background: #F5C139;
            border-radius: 30px;
			border: 3px solid #f39e12;
            transition: 0.3s;
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 20px;

            .nav-main__link {
              width: 100%;
              padding: 5px 20px;
              position: relative;
              &:after {
                content: "";
                width: 7px;
                height: 7px;
                border-top: 1px solid #FFFFFF;
                border-left: 1px solid #FFFFFF;
                transform: translateY(-50%) rotate(-135deg);
                position: absolute;
                top: 50%;
                right: 20px;
                transition: 0.3s;

              }

              .nav-main__name {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
              }
            }

            .nav-main__dropdown {
              width: 100%;
			  min-width: 151px;
			  border: 3px solid #f39e12;
              position: absolute;
              top: 0;
              left: -3px;
              opacity: 0;
              visibility: hidden;
              transition: 0.3s;
              z-index: 999;
              padding: 15px 0 20px;
              background: #F5C139;
              border-radius: 0px 0px 30px 30px;

              .nav-main__dropdown__item {
                width: 100%;
                padding: 0 10px;

                .nav-main__dropdown__link {
                  width: 100%;
                  padding: 10px 10px;
                  font-weight: 500;
                  font-size: 14px;
                  color: #FFFFFF;
                }
              }

              .nav-main__dropdown__item:hover {
                background: rgba(255, 255, 255, 0.15);
              }
            }
            .nav__down {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }
           .nav__arrow:after {
              transition: 0.3s;
              transform: rotate(45deg);
            } 
          }
            .item__down{
              border-radius: 20px 20px 0 0 ;
            }
          .nav-main__item-themes {
            .nav-main__dropdown-themes {
              width: 364px;
              border-radius: 2px 30px 30px 30px;
              display: flex;
              flex-wrap: wrap;

              .nav-main__dropdown__item {
                width: 49%;

                .nav-main__dropdown__link {
                  display: flex;
                  align-items: center;
                  padding: 10px 5px;

                  .nav-main__dropdown__text {
                    margin-left: 10px;
                  }
                }
              }

              .nav-main__dropdown__item:hover {
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.15);
              }
            }
          }
        }
      }

    }

.header__logo {
  .header__logo__text {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.3;
    color: #FFFFFF;

    img {
      height:30px;
    }
  }
}
.header-search {
  max-width: 229px;
  width: 100%;
  cursor: pointer;
  .header-search__form {
    width: 100%;
    .header-search__block {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .header-search__btn{
        position: absolute;
        left: 15px;
        top: 12px;
        z-index: 999;
        background: unset;
        img{
          width: 14px;
        }
      }
      .header-search__label {
        width: 100%;
        height: 37px;
        position: relative;
        display: block;
        .header-search__inp {
          width: 100%;
          height: 37px;
          background: #F5C139;
          border-radius: 30px;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.3;
          padding-left: 33px;
          color: #FFFFFF;

          &::placeholder {
            font-size: 14px;
            line-height: 1.3;
            color: #FFFFFF;
          }

        }
      }
      .search-form__control{
        display: none;
        background: unset;
        color: white;
        font-size: 18px;
      }
    }
  }
}


@media(max-width: 767.98px) {

.header .header-block .header-menu .nav-main .nav-main__list .nav-main__item:hover .nav-main__dropdown {

                 border:none;
                left: 0px;
}

  .header {
    .header-block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-menu {
        .open-menu {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: absolute;
          left: 35px;
          z-index: 9999;
          top: 20px;
          .open-menu__line:nth-child(2) {
            top: 10px;
          }
          .open-menu__line:nth-child(3) {
            top: 10px;
          }
          .open-menu__line:last-child {
            top: 20px;
          }
        }
        .menu-cnt {
          display: none;
          margin: 0;
          height: auto;
          width: 93%;
          position: absolute;
          z-index: 999;
          top: 10px;
          left: 25px;
          padding: 0;
          background-color: rgb(255, 255, 255);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
        }
        .nav-main {
          padding: 30px 20px;
          display: none;
          transition: 0.4s;

          .nav-main__list {
            flex-direction: column;
            .nav-main__item {
              transition: 0.4s;

              margin-top: 15px;
              width: 100%;
              margin-right: 0;
              flex-direction: column;
              border:none;
              .nav-main__dropdown__item {
                width: 95%;
                border-radius: 100px;
                margin: 5px auto;
                background: #ffffff;
                .nav-main__dropdown__link{
                  color: #000000;
                }
              }
              &:hover .nav-main__dropdown{
                position: relative;
                transition: 0.4s;

              }
              &:hover{
                border-radius: 20px;
                transition: 0.4s;

              }
              .nav-main__dropdown {
                display: none;
                position: relative;
                left:0;
                border:none;
              }
             
            }
           
            .nav-main__item-themes {
              .nav-main__dropdown-themes {
                width: 100%;
                border-radius: 30px;
                transition: 0.4s;
                height: 69vh;
                overflow-x: scroll;
                .nav-main__dropdown__item {
                  width: 95%;
                  border-radius: 100px;
                  margin: 5px auto;
                  background: #ffffff;
                  .nav-main__dropdown__link{
                    color: #000000;
                  }
                }
              }
            }

            .item__down {
                border-radius: 20px;
            }
          }
        }
      }
      .header__logo {
        margin: 0 auto;
      }
      .header-search {
        max-width: 38px;
        transition: 0.4s;
        position: absolute;
        right: 20px;
        .header-search__form {
          .header-search__block {
            .header-search__btn{
                  left: auto;
                  right: 0;
                  top: 0;
                  width: 38px;
                  height: 35px;
                  border-radius: 30px;
                  display: flex;
                  justify-content: center;
                  background: #f5c139;
                  align-items: center;
                  z-index: 1;
              img{
                width: 16px;
              }
            }
            .header-search__label {
              height: 35px;

              .header-search__inp {
                height: 35px;
                padding-left: 15px;
                &::placeholder {
                  opacity: 0;
                }
              }
            
            }
          }
        }
      }
      .search-clos{
        max-width: 250px;
        transform: 0.4s;
    
      }
      .search-add{
          display: block!important;
          position: absolute;
          right: 10px;
      }
    }
  }
.nav__down {
  display: block!important;
  }
  .active__img:after{
    display: block;
  }

}
@media (max-width: 575.98px){
  .header {
    .header-block{
      .header-menu {
       .menu-cnt {
        top: 10px;
        left: 15px;
      }
     }
    }
  }
  

   
}