.tasks-href {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .tasks-search{
    max-width: 282px;
    width: 100%;
    @media only screen and (max-width: 991.98px) {
      max-width: 100%;
    }
    .tasks-search__form{
      width: 100%;
    }
    .tasks-search__block{
      width: 100%;
      .tasks-search__label{
        width: 100%;
        position: relative;
          .tasks-search__inp{
            width: 100%;
            height: 37px;
            background: #F4F4F4;
            border-radius: 30px;
            font-weight: normal;
            font-size: 14px;
            color: #313131;
            padding-left: 30px;
            &::placeholder{
              font-weight: normal;
              font-size: 14px;
              color: #313131;
            }
          }
          &:after {
            content: "";
            background-image: url('/src/img/svg/icon-search.svg');
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 2px;
            left: 10px;
            width: 14px;
            height: 14px;
          }
      }
    }
  }
  .tasks__text__block{
    display: none;
  }
.tasks__cart{
  display: flex;
  align-items: start;
  position: relative;
  .tasks__text{
    padding-left: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: #000000;
    margin-top: 5px;

     &--detail {
       padding-top:15px;
     }
    .tasks__text__bold{
      font-weight: bold;
    }
  }
  .tasks__text__none{
    max-width: 890px;
    width: 100%;
  }

  &:after {
    content: "";
    background-image: url('/src/img/svg/Vector8.svg');
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    height: 263px;
    z-index: -11;
  }
}
.tasks-search__mob{
  display: none;
}
.repetition{
  margin: 25px 0 130px;
  .repetition__title{
    font-weight: bold;
    font-size: 24px;
    line-height: 1.3;
    color: #000000;
    display: block;
    text-align: center;
  }

}
.repetition__slider-box{
  margin-top: 20px;
  .repetition__box{
    width: 100%;
    background: #FEF8E6;
    border-radius: 30px;
    padding: 50px;
    .repetition__img{
      width: 100%;
    }
  }
}

.main-title__none{
  display: none;
}



.repetition__slider__min{
  margin: 15px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.repetition__btn {
  width: 106px;
  height: 72px;
  background: #F5C139;
  border-radius: 30px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  &--prev {
    img {
      transform: rotate(180deg);
    }
  }
}
.repetition__btn:last-child{
  margin-left: 40px;
}

.repetition__btn img{
  width: 46px;
  height: 26px;
}
// .repetition__btn:first-child img{
//   transform: rotate(180deg);
// }




@media (max-width: 1399.98px){
  .tasks__cart:after {
      right: 0;
  }


}
@media (max-width: 1299.98px){

  .repetition__box {
    max-width: 990px;
    margin: 0 auto;
  }

}
@media (max-width: 1169.98px) {
  .repetition__slider .slick-prev {
    left: 35%;

  }
  .repetition__slider .slick-next {
    right: 35%;

  }
}

@media (max-width: 991.98px){
  .pages .tasks__cart:after {
   display: none;
  }
  .repetition__box {
    padding: 36px;
  }
  .repetition__slider .slick-next, .repetition__slider .slick-prev {
    width: 90px;
    height: 65px;
  }
  .repetition__slider .slick-prev:after {
    left: 24px;
    top: 19px;
  }
  .repetition__slider .slick-next:after {
    right: 25px;
    top: 19px;

  }
  .tasks__text__none{
    // display: none;
  }
  .tasks__text__block{
    display: block;
    max-width: 700px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #000;
    margin: 0px 0 15px;

  }
   .tasks__text{
    .tasks__text__bold{
      font-size: 15px;
      
    }
  }
  .tasks-search{
    display: none;
  }
  .tasks-search__mob{
    display: block!important;
    margin: 5px  0;
  }
 .tasks__cart .pages__box {
    width: 170px;
    margin: 20px 0px;
}

}
@media (max-width: 767.98px){
  
  .main-title__none{
    // display: block;
    display: none;
    .main-title__text {
      text-align: left;
      &:after {
        top: -22px;
      }
    }
  }
  .tasks__cart{
    .tasks__info {
      // max-width: 140px;
      .main-title{
        // display: none;
      }

      .tasks__text {
        padding-left: 10px;
        font-size: 16px;
      }
    }
  }
  .repetition{
    position: relative;
    margin: 50px 0 60px;
    .repetition__title{
        font-size: 17px;
        position: absolute;
        max-width: 450px;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        top: 33px;
      z-index: 999;
    }
    .repetition__box {
      padding: 80px 36px 36px;
    }
  }
  .repetition__btn {
    width: 66px;
    height: 45px;
  }
  .repetition__btn img {
    width: 27px;
    height: 15px;
  }



}

@media (max-width: 575.98px){
  .min-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .tasks__cart .pages__box{
    margin: 20px 0;
  }

  .repetition {
    margin: 35px 0 30px;
    .repetition__title {
      width: 100%;
       font-size: 16px;
        max-width: 270px;
        top: 19px;
    }
    .repetition__box {
      padding: 75px 10px 85px;
    }
  }
 .repetition__slider__min {
    margin: -65px auto 0;
    max-width: 150px;
  }
  .tasks__text__block{
    font-weight: normal;
    font-size: 15px;
    line-height: 1.3;

  }
}
.tasks__cart {
  .pages__box {
    @media only screen and (max-width: 1024px) {
      position: absolute;
      width: 210px;
      top: 0;
    }
  }
}


.tasks__info {
  .main-title,
  .tasks__text {
    // @media only screen and (max-width: 1024px) {
    //   position: absolute;
    //   top: 0;
    //   left: 240px;
    // }
    // @media only screen and (max-width: 568px) {
    //   left: 200px;
    // }
    // @media only screen and (max-width: 479px) {
    //   left: 140px;
    // }
  }  
}

.tasks-wrap {
  @media only screen and (max-width: 1024px) {
    position: absolute;
    top: 50px;
    left: 224px;
  }
  @media only screen and (max-width: 768px) {
    top: 30px;
    left: 200px;
  }
  @media only screen and (max-width: 479px) {
    top: 0;
    left: 140px;
  }
  .main-title {
    @media only screen and (max-width: 1024px) {
      padding-left: 10px;
    }
  }
  .tasks__text {
    @media only screen and (max-width: 1024px) {
      padding-left: 0;
    }
  }
}

.tasks__info {
  .tasks__text {
    &:first-of-type {
      @media only screen and (max-width: 1024px) {
        top: 140px;
      }
    }
    &:nth-of-type(2) {
      @media only screen and (max-width: 1024px) {
        top: 100px;
      }
    }
  }  
}

.tasks__info {
  .tasks__text__none {
    @media only screen and (max-width: 1024px) {
      position: static;
    }
  }
}

.tasks__cart {
  @media only screen and (max-width: 1024px) {
    padding-top: 360px;
    padding-bottom: 15px;
  }
  @media only screen and (max-width: 568px) {
    padding-top: 260px;
  }
  @media only screen and (max-width: 479px) {
    padding-top: 240px;
  }
}


.main-title .main-title__text {
  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.tasks__info {
  .main-title {
    @media only screen and (max-width: 568px) {
      top: 30px;
      padding-left: 10px;
    }
  }
}

.tasks__cart .pages__box {
  @media only screen and (max-width: 568px) {
    width: 180px;
    height: 220px;
  }
  @media only screen and (max-width: 479px) {
    width: 130px;
    height: 200px;
  }
}
