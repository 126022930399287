.breadcrumbs{
  background: #F4F4F4;
  border-radius: 30px;
  width: fit-content;
  .breadcrumbs__list{
    display: flex;
    align-items: center;
    padding: 10px 15px;
    .breadcrumbs__item{
       margin-right: 5px;
       font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #313131;
      .breadcrumbs__link{
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #313131;
        &:hover{
          text-decoration: underline;
        }
      }

    }
    .breadcrumbs__item + .breadcrumbs__item{
      position: relative;
      padding-left: 10px;
      &:after{
        content: '/';
        position: absolute;
        left: 0;
      }
    }
  }
}


@media (max-width: 575.98px){
  .breadcrumbs{
    .breadcrumbs__list{
      flex-wrap: wrap;
      .breadcrumbs__item{
        margin-right: 15px;

      }
    }
  }

}