@charset "UTF-8";
@import url(magnific-popup.css);
@import url(../slick/slick.css);
@import url(../slick/slick-theme.css);
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.ttf");
  font-weight: normal; }

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
iframe,
ol,
ul,
li,
form,
label,
i,
footer,
header,
menu,
nav,
a,
table,
thead,
tbody,
tfoot,
tr,
th,
td,
video,
canvas,
section,
applet,
object,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
ins,
kbd,
hgroup,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
dl,
dt,
dd,
fieldset,
legend,
article,
aside,
details,
embed,
figure,
figcaption,
output,
section,
summary,
time,
mark,
audio,
center,
ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  position: relative; }

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

* {
  outline: none !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a {
  display: inline-block;
  text-decoration: none; }

button {
  cursor: pointer; }

input,
button,
select {
  display: inline-block;
  padding: 0;
  border: 0; }

img {
  max-width: 100%;
  max-height: 100%; }

body {
  font-family: 'Roboto','sans-serif';
  margin: 0 auto;
  padding: 0; }

.max-container {
  max-width: 1414px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.min-container {
  max-width: 1240px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.home-page {
  background: #FEF8E6;
  border-radius: 30px;
  padding: 80px 50px 50px;
  margin-top: 50px;
  margin-bottom: 80px;
  position: relative; }
  .home-page .home-page__title {
    position: absolute;
    top: 40px;
    left: 310px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #000000; }
  .home-page .cs-link-icon {
    width: 20px; }

.home-page__min .cell__class-number {
  display: none; }

.home-page__min .home__link {
  position: relative;
  width: 94px;
  height: 70px;
  text-decoration: none;
  background-color: #fff;
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center; }

.home-page__min .home__href__hide {
  pointer-events: none; }

.home-page__min .home-page__number {
  display: flex;
  justify-content: space-between; }
  .home-page__min .home-page__number .home__link {
    border-radius: 30px 30px 0 0;
    background-color: #fbfbfb; }
  .home-page__min .home-page__number .home__link:nth-child(odd) {
    background-color: #FFFFFF; }
  .home-page__min .home-page__number .home__link:hover {
    background-color: #f3f3f3; }
  .home-page__min .home-page__number .empty__items {
    padding-left: 20px; }
  .home-page__min .home-page__number .page__empty {
    min-width: 250px;
    background-color: #fef8e6 !important;
    cursor: default;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    padding-left: 20px;
    color: #000000; }
    .home-page__min .home-page__number .page__empty .home__href {
      border-radius: 30px 30px 0  0; }

.home-page__min .hover {
  background-color: rgba(253, 202, 85, 0.36) !important; }

.home-page__min .hover:hover {
  background-color: #fdca55 !important; }

.home-page__min .home-page__item:nth-child(2) .home__link:first-child {
  border-radius: 30px 0 0; }

.home-page__min .home-page__item .home__link:first-child {
  max-width: 250px;
  width: 100%;
  padding-left: 20px;
  justify-content: flex-start; }

.home-page__min .home-page__item:last-child .home__link:first-child {
  border-radius: 0 0 0 30px; }

.home-page__min .home-page__item:last-child .home__link:last-child {
  border-radius: 0 0 30px 0; }

.home-page__min .home-page__box {
  display: flex;
  justify-content: space-between; }
  .home-page__min .home-page__box .home__link .s-link {
    height: 100%;
    min-width: 250px;
    max-width: 250px;
    background-color: #fff;
    padding: 0 15px 0 35px;
    -webkit-box-pack: start;
    justify-content: flex-start; }
  .home-page__min .home-page__box .home__link .s-link__name {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.3;
    color: #000000;
    margin-left: 10px; }
  .home-page__min .home-page__box .home__link .flag {
    width: 26px;
    height: 18px; }
  .home-page__min .home-page__box .home__availability:nth-child(2n) {
    background-color: #fbfbfb; }
  .home-page__min .home-page__box .is-active:hover {
    background-color: #f3f3f3; }

@media (max-width: 1399.98px) {
  .home-page {
    margin: 50px auto 80px; } }

@media (max-width: 1299.98px) {
  .home-paes__img {
    top: -79px;
    max-width: 242px;
    height: 522px; } }

@media (max-width: 1169.98px) {
  .home-paes__img {
    display: none; }
  .home-page {
    padding: 70px 35px 40px;
    margin-top: 45px;
    margin-bottom: 60px; }
    .home-page .home-page__title {
      top: 40px;
      left: 280px;
      font-size: 20px; }
  .home-page__min .home-page__box .home__link .s-link__name {
    font-size: 16px;
    margin-left: 5px; }
  .home-page__min .home__link {
    width: 80px;
    height: 60px;
    font-size: 18px; }
    .home-page__min .home__link .flag {
      width: 20px;
      height: 15px; }
  .home-page__min .home-page__item .home__link:first-child {
    max-width: 200px;
    padding-left: 15px; }
  .home-page__min .home-page__number .empty__items {
    padding-left: 15px; }
  .home-page__min .home-page__number .page__empty {
    min-width: 200px;
    font-size: 18px;
    padding-left: 15px; } }

@media (max-width: 991.98px) {
  .home-page__min .home-page__box .home__availability:nth-child(2n) {
    background-color: #F5C139; }
  .active__down {
    display: none !important; }
  .active__arrow {
    border-radius: 20px !important; }
    .active__arrow:after {
      transform: translateY(-50%) rotate(-135deg) !important; }
  .home-page {
    padding: 20px 5px 30px;
    margin: 40px auto;
    max-width: 100%; }
  .home-page__title {
    display: none; }
  .home-page__min .home-page__item:nth-child(2) .home__link:first-child {
    border-radius: 20px 20px 0 0; }
  .home-page__min .page__empty {
    min-width: auto !important; }
  .home-page__min .hover {
    background-color: #f5c139 !important; }
  .home-page__min .hover:hover {
    background-color: #FFD25C !important; }
  .home-page__min .home-page__box {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center; }
    .home-page__min .home-page__box .is-active {
      background-color: #ffd25c !important; }
    .home-page__min .home-page__box .home__availability {
      background-color: #F5C139; }
      .home-page__min .home-page__box .home__availability:not(.is-active) {
        pointer-events: none; }
    .home-page__min .home-page__box .home__availability:nth-child(2) {
      border-radius: 0 0 0 20px !important; }
    .home-page__min .home-page__box .home__availability:last-child {
      border-radius: 0 0 20px 0 !important; }
  .home-page__min .home__link {
    max-width: 9%;
    width: 100%;
    height: 35px;
    font-size: 15px;
    background: #F5C139;
    text-align: center;
    padding-top: 3px;
    transition: 0.4s; }
  .home-page__min .home-page__box {
    transition: 0.4s; }
  .home-page__min .home__href {
    display: none; }
  .home-page__min .home-page__item .home__link:first-child {
    display: block;
    max-width: 100%;
    margin: 0 2.5px;
    border-radius: 20px 20px 0 0;
    position: relative;
    padding: 10px 15px;
    height: auto;
    text-align: left;
    background: #FFFFFF !important; }
  .home-page__min .home-page__item .cs-link-icon {
    display: none; }
  .home-page__min .home-page__item .flag {
    width: 19px;
    height: 14px; }
  .is-active .cell__class-number {
    color: #FFFFFF !important; }
  .home__availability .cell__class-number {
    display: block;
    font-weight: normal;
    font-size: 17px;
    line-height: 1.2;
    color: #FFFFFF; }
  .home-page__number .page__empty {
    min-width: 100%;
    font-size: 18px;
    padding-left: 0;
    justify-content: center;
    margin-bottom: 20px; }
  .is-active:hover {
    background: #FFD25C !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05); }
  .home-page__min .home-page__item:last-child .home__link:first-child {
    border-radius: 20px 20px 0 0; } }

@media only screen and (max-width: 479px) {
  .home-page__min .home-page__number .page__empty {
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 8px; }
    .home-page__min .home-page__number .page__empty span {
      font-weight: 700; } }

@media only screen and (max-width: 991.98px) {
  .cart__bitwin .pages__content {
    max-width: 75%;
    justify-content: center; } }

.container {
  max-width: 1210px;
  width: 100%;
  margin: 0 auto; }

.accordeon {
  background: #FEF8E6;
  padding: 40px;
  border-radius: 30px; }

.accordeon__item {
  background: #FFFFFF;
  border-radius: 30px; }

.item_even {
  background: #F9F9F9; }

.accordeon__category {
  display: flex;
  align-items: center; }

.accordeon__category__number {
  padding: 5px 8px;
  margin-left: 35px;
  border-radius: 30px;
  background-color: #f2a90d;
  color: #fff;
  font-size: 15px; }

.accordeon__item:not(:last-child) {
  margin-bottom: 15px; }

.accordeon__item .accordeon__item {
  margin: 0 23px; }

.accordeon__head {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 0 60px 0 23px;
  border-radius: 15px;
  font-size: 22px;
  color: #000;
  cursor: pointer;
  border-radius: 30px; }

.accordeon__item.active > .accordeon__head:before {
  transform: translateY(-50%) rotate(45deg);
  transition: 0.4s; }

.accordeon__none__bef .accordeon__head {
  min-height: 60px; }

.accordeon > .active {
  padding-bottom: 15px; }

.accordeon__none__bef .accordeon__head:before {
  display: none; }

.accordeon__none__bef {
  margin-top: 15px;
  margin-bottom: 10px; }

.accordeon__head:before {
  content: "";
  position: absolute;
  right: 30px;
  width: 10px;
  height: 10px;
  border-top: 1.5px solid #9C9C9C;
  border-left: 1.5px solid #9C9C9C;
  top: 50%;
  transform: translateY(-50%) rotate(-135deg);
  transition: 0.4s; }

.accordeon__head:hover, .accordeon__number:hover {
  transition: 0.4s;
  background-color: #f5c139;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03); }

.accordeon__head:hover {
  color: #fff;
  transition: 0.2s; }

.accordeon__number:hover {
  color: #fff; }

.accordeon__main {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px; }

.accordeon__list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -5px 0;
  padding-bottom: 15px;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px; }

.accordeon__list li {
  margin: 10px 5px 0; }

.accordeon__list--row .accordeon__li {
  width: 100%;
  flex: 100%; }

.accordeon__list--row .accordeon__number {
  justify-content: flex-start;
  height: auto;
  padding: 20px; }

.accordeon__number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F9F9F9;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  transition: 0.4s;
  text-decoration: none; }

.accordeon__list--row .accordeon__number {
  justify-content: flex-start;
  width: max-content;
  height: auto;
  width: auto;
  padding: 20px; }

.accordeon__list--row .accordeon__li {
  width: 100%;
  flex: 100%; }

@media (max-width: 575.98px) {
  .accordeon {
    padding: 20px 15px; }
  .accordeon__head {
    padding: 0 40px 0 19px;
    font-size: 16px;
    min-height: 50px; }
  .accordeon__item .accordeon__item {
    margin: 0 15px; }
  .accordeon__list--row .accordeon__number {
    padding: 15px;
    font-size: 19px; }
  .accordeon__head:before {
    right: 20px; }
  .accordeon__list {
    margin: -10px 5px 0; }
  .accordeon__list li {
    margin: 10px 4px 0; }
  .accordeon__number {
    border-radius: 10px;
    width: 35px;
    height: 35px;
    font-size: 15px; } }

.header {
  width: 100%;
  background: linear-gradient(267.13deg, #F48C1D 0%, #F1B904 100%);
  border-radius: 0px 0px 30px 30px;
  height: 65px;
  display: flex;
  z-index: 9999; }
  .header .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.header-menu .open-menu {
  display: none;
  width: 30px;
  height: 24px;
  position: relative; }
  .header-menu .open-menu .open-menu__line {
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: white;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.header-menu .close-menu {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 30px; }
  .header-menu .close-menu .open-menu__line {
    height: 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #313131;
    z-index: 99999; }
  .header-menu .close-menu .open-menu__line:first-child {
    display: none; }
  .header-menu .close-menu .open-menu__line:last-child {
    display: none; }
  .header-menu .close-menu .open-menu__line:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .header-menu .close-menu .open-menu__line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }

.header-menu .nav-main .nav-main__list {
  display: flex;
  align-items: center; }
  .header-menu .nav-main .nav-main__list .nav-main__item {
    width: 151px;
    padding: 4px 0;
    background: #F5C139;
    border-radius: 30px;
    border: 3px solid #f39e12;
    transition: 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px; }
    .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__link {
      width: 100%;
      padding: 5px 20px;
      position: relative; }
      .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__link:after {
        content: "";
        width: 7px;
        height: 7px;
        border-top: 1px solid #FFFFFF;
        border-left: 1px solid #FFFFFF;
        transform: translateY(-50%) rotate(-135deg);
        position: absolute;
        top: 50%;
        right: 20px;
        transition: 0.3s; }
      .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__link .nav-main__name {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF; }
    .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown {
      width: 100%;
      min-width: 151px;
      border: 3px solid #f39e12;
      position: absolute;
      top: 0;
      left: -3px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      z-index: 999;
      padding: 15px 0 20px;
      background: #F5C139;
      border-radius: 0px 0px 30px 30px; }
      .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown .nav-main__dropdown__item {
        width: 100%;
        padding: 0 10px; }
        .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown .nav-main__dropdown__item .nav-main__dropdown__link {
          width: 100%;
          padding: 10px 10px;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF; }
      .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown .nav-main__dropdown__item:hover {
        background: rgba(255, 255, 255, 0.15); }
    .header-menu .nav-main .nav-main__list .nav-main__item .nav__down {
      opacity: 1;
      visibility: visible;
      top: 100%; }
    .header-menu .nav-main .nav-main__list .nav-main__item .nav__arrow:after {
      transition: 0.3s;
      transform: rotate(45deg); }
  .header-menu .nav-main .nav-main__list .item__down {
    border-radius: 20px 20px 0 0; }
  .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes {
    width: 364px;
    border-radius: 2px 30px 30px 30px;
    display: flex;
    flex-wrap: wrap; }
    .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item {
      width: 49%; }
      .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item .nav-main__dropdown__link {
        display: flex;
        align-items: center;
        padding: 10px 5px; }
        .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item .nav-main__dropdown__link .nav-main__dropdown__text {
          margin-left: 10px; }
    .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item:hover {
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.15); }

.header__logo .header__logo__text {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  color: #FFFFFF; }
  .header__logo .header__logo__text img {
    height: 30px; }

.header-search {
  max-width: 229px;
  width: 100%;
  cursor: pointer; }
  .header-search .header-search__form {
    width: 100%; }
    .header-search .header-search__form .header-search__block {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative; }
      .header-search .header-search__form .header-search__block .header-search__btn {
        position: absolute;
        left: 15px;
        top: 12px;
        z-index: 999;
        background: unset; }
        .header-search .header-search__form .header-search__block .header-search__btn img {
          width: 14px; }
      .header-search .header-search__form .header-search__block .header-search__label {
        width: 100%;
        height: 37px;
        position: relative;
        display: block; }
        .header-search .header-search__form .header-search__block .header-search__label .header-search__inp {
          width: 100%;
          height: 37px;
          background: #F5C139;
          border-radius: 30px;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.3;
          padding-left: 33px;
          color: #FFFFFF; }
          .header-search .header-search__form .header-search__block .header-search__label .header-search__inp::placeholder {
            font-size: 14px;
            line-height: 1.3;
            color: #FFFFFF; }
      .header-search .header-search__form .header-search__block .search-form__control {
        display: none;
        background: unset;
        color: white;
        font-size: 18px; }

@media (max-width: 767.98px) {
  .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item:hover .nav-main__dropdown {
    border: none;
    left: 0px; }
  .header .header-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header .header-block .header-menu .open-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: absolute;
      left: 35px;
      z-index: 9999;
      top: 20px; }
      .header .header-block .header-menu .open-menu .open-menu__line:nth-child(2) {
        top: 10px; }
      .header .header-block .header-menu .open-menu .open-menu__line:nth-child(3) {
        top: 10px; }
      .header .header-block .header-menu .open-menu .open-menu__line:last-child {
        top: 20px; }
    .header .header-block .header-menu .menu-cnt {
      display: none;
      margin: 0;
      height: auto;
      width: 93%;
      position: absolute;
      z-index: 999;
      top: 10px;
      left: 25px;
      padding: 0;
      background-color: white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 20px; }
    .header .header-block .header-menu .nav-main {
      padding: 30px 20px;
      display: none;
      transition: 0.4s; }
      .header .header-block .header-menu .nav-main .nav-main__list {
        flex-direction: column; }
        .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item {
          transition: 0.4s;
          margin-top: 15px;
          width: 100%;
          margin-right: 0;
          flex-direction: column;
          border: none; }
          .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown__item {
            width: 95%;
            border-radius: 100px;
            margin: 5px auto;
            background: #ffffff; }
            .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown__item .nav-main__dropdown__link {
              color: #000000; }
          .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item:hover .nav-main__dropdown {
            position: relative;
            transition: 0.4s; }
          .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item:hover {
            border-radius: 20px;
            transition: 0.4s; }
          .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item .nav-main__dropdown {
            display: none;
            position: relative;
            left: 0;
            border: none; }
        .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes {
          width: 100%;
          border-radius: 30px;
          transition: 0.4s;
          height: 69vh;
          overflow-x: scroll; }
          .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item {
            width: 95%;
            border-radius: 100px;
            margin: 5px auto;
            background: #ffffff; }
            .header .header-block .header-menu .nav-main .nav-main__list .nav-main__item-themes .nav-main__dropdown-themes .nav-main__dropdown__item .nav-main__dropdown__link {
              color: #000000; }
        .header .header-block .header-menu .nav-main .nav-main__list .item__down {
          border-radius: 20px; }
    .header .header-block .header__logo {
      margin: 0 auto; }
    .header .header-block .header-search {
      max-width: 38px;
      transition: 0.4s;
      position: absolute;
      right: 20px; }
      .header .header-block .header-search .header-search__form .header-search__block .header-search__btn {
        left: auto;
        right: 0;
        top: 0;
        width: 38px;
        height: 35px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        background: #f5c139;
        align-items: center;
        z-index: 1; }
        .header .header-block .header-search .header-search__form .header-search__block .header-search__btn img {
          width: 16px; }
      .header .header-block .header-search .header-search__form .header-search__block .header-search__label {
        height: 35px; }
        .header .header-block .header-search .header-search__form .header-search__block .header-search__label .header-search__inp {
          height: 35px;
          padding-left: 15px; }
          .header .header-block .header-search .header-search__form .header-search__block .header-search__label .header-search__inp::placeholder {
            opacity: 0; }
    .header .header-block .search-clos {
      max-width: 250px;
      transform: 0.4s; }
    .header .header-block .search-add {
      display: block !important;
      position: absolute;
      right: 10px; }
  .nav__down {
    display: block !important; }
  .active__img:after {
    display: block; } }

@media (max-width: 575.98px) {
  .header .header-block .header-menu .menu-cnt {
    top: 10px;
    left: 15px; } }

.breadcrumbs {
  background: #F4F4F4;
  border-radius: 30px;
  width: fit-content; }
  .breadcrumbs .breadcrumbs__list {
    display: flex;
    align-items: center;
    padding: 10px 15px; }
    .breadcrumbs .breadcrumbs__list .breadcrumbs__item {
      margin-right: 5px;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #313131; }
      .breadcrumbs .breadcrumbs__list .breadcrumbs__item .breadcrumbs__link {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #313131; }
        .breadcrumbs .breadcrumbs__list .breadcrumbs__item .breadcrumbs__link:hover {
          text-decoration: underline; }
    .breadcrumbs .breadcrumbs__list .breadcrumbs__item + .breadcrumbs__item {
      position: relative;
      padding-left: 10px; }
      .breadcrumbs .breadcrumbs__list .breadcrumbs__item + .breadcrumbs__item:after {
        content: '/';
        position: absolute;
        left: 0; }

@media (max-width: 575.98px) {
  .breadcrumbs .breadcrumbs__list {
    flex-wrap: wrap; }
    .breadcrumbs .breadcrumbs__list .breadcrumbs__item {
      margin-right: 15px; } }

.main-title {
  margin-top: 40px; }
  .main-title .main-title__text {
    position: relative;
    padding-left: 30px;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.3;
    color: #313131; }
    .main-title .main-title__text:after {
      content: "";
      background-image: url("/src/img/svg/Vector.svg");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -22px;
      left: 0;
      width: 98px;
      height: 97px;
      z-index: -1; }

@media (max-width: 991.98px) {
  .main-title .main-title__text {
    font-size: 32px; } }

@media (max-width: 767.98px) {
  .main-title .main-title__text {
    font-size: 28px; } }

@media (max-width: 575.98px) {
  .main-title {
    margin-top: 25px; }
    .main-title .main-title__text {
      font-size: 18px;
      padding-left: 0; }
      .main-title .main-title__text:after {
        top: -15px;
        left: -15px;
        width: 69px;
        height: 69px; } }

.pages__cart {
  max-width: 1000px;
  width: 100%; }
  .pages__cart .pages__info {
    display: flex;
    justify-content: center; }
    .pages__cart .pages__info .pages__name {
      font-weight: bold;
      font-size: 36px;
      line-height: 1.3;
      color: #313131;
      position: relative;
      display: block; }
      .pages__cart .pages__info .pages__name:after {
        content: "";
        background-image: url("/src/img/svg/Vector2.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: -19px;
        left: -49px;
        width: 97px;
        height: 87px;
        z-index: -1; }

.pages__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px 0 30px; }

.pages__box {
  width: 210px;
  margin: 20px 15px 20px 15px;
  height: auto;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 30px;
  min-height: 300px;
  overflow: hidden; }
  .pages__box--full {
    flex-basis: 100%;
    max-width: 210px;
    min-width: 210px; }
  .pages__box img {
    border-radius: 30px; }
  .pages__box:hover .pages__href {
    z-index: 0; }
  .pages__box .pages__href {
    width: 100%;
    position: relative;
    transition: 0.2s ease;
    height: 100%;
    z-index: -1;
    position: absolute; }
    .pages__box .pages__href .pages__box__click {
      width: 100%;
      background: linear-gradient(269.93deg, #F48C1D 0%, #F1B904 100%);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
      border-radius: 30px;
      padding-top: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
      .pages__box .pages__href .pages__box__click .pages__box__text {
        font-weight: normal;
        font-size: 17px;
        line-height: 1.3;
        color: #FFFFFF;
        text-align: center;
        margin-top: 5px;
        padding: 0 35px; }
      .pages__box .pages__href .pages__box__click .pages__box__bold {
        font-weight: 700;
        margin: 8px 0;
        font-size: 17px;
        line-height: 1.3;
        color: #FFFFFF;
        text-align: center;
        margin-top: 5px;
        padding: 0 35px; }
      .pages__box .pages__href .pages__box__click .pages__box__number {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.1;
        display: block;
        text-align: center;
        color: #FFFFFF; }
      .pages__box .pages__href .pages__box__click .pages__box__name {
        font-weight: normal;
        font-size: 20px;
        line-height: 1.1;
        color: #FFFFFF;
        display: block;
        text-align: center;
        margin-top: 0px; }
      .pages__box .pages__href .pages__box__click .pages__box__btn {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5C139;
        border-radius: 50px 50px 30px 30px;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 10px;
        position: absolute;
        bottom: 0; }
    .pages__box .pages__href:after {
      display: none;
      content: "";
      background-image: url("/src/img/photo/ximik.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
      z-index: 1;
      border-radius: 10px; }
  .pages__box:hover .pages__href:after {
    transition: 0.5s;
    display: none; }

.cart__big {
  max-width: 100%; }

.cart__bitwin .pages__content {
  justify-content: space-between; }

.tasks__cart__img {
  width: 100%; }

@media (max-width: 1599.98px) {
  .pages__content .pages__box {
    margin: 20px 12px; } }

@media (max-width: 1499.98px) {
  .pages__cart {
    max-width: 940px; }
    .pages__cart .pages__content {
      margin: 25px 0 0; }
  .cart__big {
    max-width: 100%; } }

@media (max-width: 1399.98px) {
  .pages__cart {
    max-width: 840px; }
    .pages__cart .pages__content {
      margin: 25px 0 0; }
      .pages__cart .pages__content .pages__box .pages__box__click .pages__box__text {
        margin-top: 0; }
  .cart__big {
    max-width: 100%; } }

@media (max-width: 1299.98px) {
  .pages__cart {
    max-width: 840px; }
  .cart__big {
    max-width: 100%; } }

@media (max-width: 1169.98px) {
  .pages__cart {
    margin: 0 auto; }
    .pages__cart .pages__info .pages__name {
      font-size: 32px; }
    .pages__cart .pages__content {
      justify-content: flex-start; }
  .cart__bitwin .pages__content {
    margin: 0 auto;
    justify-content: center; }
    .cart__bitwin .pages__content .pages__box {
      margin: 20px 10px; } }

@media (max-width: 991.98px) {
  .cart__bitwin .pages__content {
    max-width: 75%;
    justify-content: center;
    margin: 0 auto; }
    .cart__bitwin .pages__content .pages__box {
      margin: 20px 13px; } }

@media (max-width: 767.98px) {
  .pages__cart {
    margin-top: 25px; }
    .pages__cart .pages__info .pages__name {
      font-size: 25px;
      text-align: center; }
    .pages__cart .pages__content {
      margin: 10px 0 0; } }

@media (max-width: 575.98px) {
  .pages__cart .pages__content .pages__box {
    margin: 20px 5px;
    width: 206px; } }

@media only screen and (max-width: 992px) {
  .pages__cart .pages__content {
    justify-content: flex-start; } }

@media only screen and (max-width: 768px) {
  .pages__cart .pages__content {
    justify-content: center; } }

@media only screen and (max-width: 568px) {
  .pages__cart .pages__content {
    flex-direction: column;
    align-items: center; } }

@media only screen and (max-width: 992px) {
  .pages__cart .pages__content--medium {
    justify-content: center;
    margin: 0 auto; } }

.pages__cart .pages__content--center {
  justify-content: center; }
  @media only screen and (max-width: 1169.98px) {
    .pages__cart .pages__content--center {
      justify-content: center; } }
  @media only screen and (max-width: 1024px) {
    .pages__cart .pages__content--center {
      max-width: 75%;
      margin: 0 auto; } }
  @media only screen and (max-width: 768px) {
    .pages__cart .pages__content--center {
      max-width: 100%; } }

.sidebar-nav__block {
  max-width: 353px;
  width: 100%;
  background: #F5C139;
  border-radius: 30px;
  padding-top: 20px;
  padding-bottom: 20px; }
  .sidebar-nav__block .sidebar-nav__title {
    font-weight: 600;
    font-size: 25px;
    line-height: 1.3;
    color: #FFFFFF;
    text-align: center;
    padding: 10px 25px; }
  .sidebar-nav__block .sidebar-nav__list {
    margin-top: 15px; }
    .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link {
      display: flex;
      align-items: center;
      padding: 10px 25px 10px 30px;
      transition: 0.4s; }
      .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link .flag {
        width: 26px;
        margin-right: 15px; }
      .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link .sidebar-nav__dropdown__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2;
        color: #FFFFFF; }
      .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link:hover {
        transition: 0.4s;
        background: rgba(255, 255, 255, 0.15); }
  .sidebar-nav__block .sidebar-nav__big {
    max-width: 100% !important; }

@media (max-width: 1399.98px) {
  .finished-min__block {
    max-width: 300px; } }

@media (max-width: 1299.98px) {
  .sidebar-nav__block {
    max-width: 270px; }
    .sidebar-nav__block .sidebar-nav__title {
      padding: 10px 20px; }
  .sidebar-nav__list .sidebar-nav__dropdown__link {
    padding: 10px 20px; } }

@media (max-width: 991.98px) {
  .women-fon {
    display: none; } }

@media (max-width: 1024.98px) {
  .sidebar-nav__block {
    position: relative;
    max-width: 353px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .sidebar-nav__block .sidebar-nav__title {
      font-size: 22px;
      padding: 20px 25px;
      width: 100%;
      display: block;
      text-align: left; }
    .sidebar-nav__block .sidebar-nav__list {
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      z-index: 99;
      padding: 15px 0 20px;
      background: #F5C139;
      border-radius: 10px 10px 30px 30px; }
    .sidebar-nav__block:after {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      -webkit-transform: translateY(-50%) rotate(-135deg);
      transform: translateY(-50%) rotate(-135deg);
      position: absolute;
      top: 50%;
      right: 20px;
      transition: 0.3s; }
  .sidebar__item {
    border-radius: 30px 30px 0px 0px; }
    .sidebar__item .sidebar-nav__list {
      opacity: 1;
      visibility: visible;
      transition: 0.3s;
      top: 50%; }
    .sidebar__item:after {
      transition: 0.3s;
      transform: rotate(45deg); } }

@media (max-width: 575.98px) {
  .sidebar-nav__block {
    position: relative;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px; }
    .sidebar-nav__block .sidebar-nav__title {
      font-size: 18px;
      padding: 10px 20px; }
    .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link {
      padding: 10px 20px; }
      .sidebar-nav__block .sidebar-nav__list .sidebar-nav__dropdown .sidebar-nav__dropdown__item .sidebar-nav__dropdown__link .sidebar-nav__dropdown__text {
        font-size: 16px !important; } }

.students .students__info h1:not(:first-child), .students .students__info h2:not(:first-child),
.content_formatter h1:not(:first-child),
.content_formatter h2:not(:first-child) {
  padding-top: 15px; }

.students .students__info ul, .students .students__info ol,
.content_formatter ul,
.content_formatter ol {
  padding-top: 15px;
  font-size: 18px;
  line-height: 1.4; }

.students .students__info li,
.content_formatter li {
  color: #797979; }

.students .students__info p:not(:first-child),
.content_formatter p:not(:first-child) {
  padding-top: 15px; }

.students .students__info h1, .students .students__info h2,
.content_formatter h1,
.content_formatter h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #000000;
  margin-bottom: 15px; }

.students .students__info p,
.content_formatter p {
  text-align: left;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.4;
  color: #000000; }

.students .students__info ul,
.content_formatter ul {
  list-style-type: disc;
  list-style-position: inside; }

.students .students__info ol,
.content_formatter ol {
  list-style-type: decimal;
  list-style-position: inside; }

.students .students__info ul ul, .students .students__info ol ul,
.content_formatter ul ul,
.content_formatter ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px; }

.students .students__info ol ol, .students .students__info ul ol,
.content_formatter ol ol,
.content_formatter ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px; }

.students .students__info li,
.content_formatter li {
  padding: 5px 0px; }

.students {
  margin: 70px 0 40px;
  display: flex;
  justify-content: flex-end;
  position: relative; }
  .students:after {
    content: "";
    background-image: url("/src/img/svg/Vector5.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: -50px;
    max-width: 350px;
    width: 100%;
    height: 250px;
    z-index: -15; }
  .students .students__fon {
    position: absolute;
    top: -70px;
    left: -59px;
    z-index: -1; }
  .students .students__info {
    max-width: 1245px;
    width: 100%; }
    .students .students__info .students__text__cirk {
      list-style-type: disc;
      list-style-position: inside;
      margin: 15px 0; }
      .students .students__info .students__text__cirk li {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin: 5px 0;
        color: #000000; }
    .students .students__info .students__text + .students__text {
      margin-top: 15px; }
    .students .students__info .students__details {
      display: none;
      background: linear-gradient(269.42deg, #F48C1D 0%, #F1B904 100%);
      border-radius: 30px;
      width: 178px;
      height: 35px;
      margin: 15px auto 0;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF; }

@media (max-width: 1599.98px) {
  .students .students__fon {
    position: absolute;
    top: -60px;
    left: -10px;
    z-index: -1; }
  .students .students__info {
    max-width: 1200px; } }

@media (max-width: 1499.98px) {
  .students:after {
    right: 0;
    max-width: 320px;
    height: 240px; }
  .students .students__info {
    max-width: 1140px; }
  .about__students .students__info {
    padding-right: 70px; } }

@media (max-width: 1399.98px) {
  .students .students__info {
    max-width: 1050px; }
    .students .students__info .students__text {
      font-size: 17px; } }

@media (max-width: 1299.98px) {
  .students .students__info {
    max-width: 1000px; }
    .students .students__info .students__text {
      font-size: 16px; } }

@media (max-width: 1169.98px) {
  .students {
    margin: 50px 0 40px; }
    .students .students__fon {
      top: -60px;
      left: 0; }
  .about__students .students__info {
    padding-right: 0; } }

@media (max-width: 991.98px) {
  .about-women-fon {
    display: none; } }

@media (max-width: 767.98px) {
  .students .students__fon {
    top: -40px;
    left: 0; } }

@media (max-width: 575.98px) {
  .students .students__info .students__title {
    font-size: 17px; }
  .students .students__info .students__text {
    font-size: 16px;
    line-height: 1.3; }
  .students .students__info .students__text__cirk {
    margin: 15px 0; }
    .students .students__info .students__text__cirk li {
      font-size: 16px; }
  .students .students__info .students__details {
    display: block; }
  .students:after {
    display: none; } }

.mfp-content {
  top: 82px; }

.pages__min__height {
  height: 100vh; }

.pages {
  position: relative; }
  .pages .pages__main {
    margin-top: 20px; }
  .pages .women-fon {
    position: absolute;
    right: 0;
    top: -75px;
    max-width: 331px;
    width: 100%;
    height: 624px;
    z-index: -1; }
  .pages .img-book {
    position: absolute;
    left: 0;
    bottom: 450px;
    width: 100px;
    height: 137px;
    z-index: -1; }
  .pages .img-book-top {
    bottom: auto;
    top: 400px; }
  .pages .img-book__right {
    right: 0;
    position: absolute;
    bottom: 450px;
    width: 125px;
    height: 137px;
    z-index: -1; }
  .pages .img-circle {
    position: absolute;
    right: 0;
    bottom: 350px;
    width: 110px;
    height: 137px;
    z-index: -1; }
  .pages .img-circle__left {
    position: absolute;
    left: 0;
    bottom: 750px;
    width: 120px;
    height: 137px;
    z-index: -1; }
  .pages .women-fon-tow {
    position: absolute;
    left: 0;
    bottom: -48px;
    max-width: 246px;
    width: 100%;
    height: 528px;
    z-index: -1; }

.pages__cnt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px; }

@media (max-width: 1499.98px) {
  .max-container {
    max-width: 1330px; } }

@media (max-width: 1399.98px) {
  .max-container {
    max-width: 1200px; }
  .pages__cnt {
    margin-top: 40px; }
  .women-fon {
    max-width: 299px;
    height: 562px; } }

@media (max-width: 1299.98px) {
  .max-container {
    padding: 0 25px; }
  .pages .img-book {
    display: none; }
  .pages .img-book-top {
    display: block; }
  .pages .tasks__fon {
    top: 160px; }
  .pages .tasks__cart:after {
    bottom: 45px;
    right: 0; }
  .women-fon {
    display: none !important; } }

@media (max-width: 1169.98px) {
  .women-fon-tow {
    display: none; } }

@media (max-width: 1024.98px) {
  .pages__main .pages__cnt {
    flex-direction: column;
    align-items: center; }
  .mob-remove {
    display: none; } }

@media (max-width: 991.98px) {
  .women-fon {
    max-width: 252px;
    height: 470px;
    top: 74px; } }

@media (max-width: 767.98px) {
  .pages .women-fon {
    display: none; }
  .pages .img-circle {
    display: none; }
  .pages .img-book__right {
    display: none; }
  .pages .img-book-top {
    display: none; }
  .pages .img-circle__left {
    display: none; } }

@media (max-width: 575.98px) {
  .max-container {
    padding: 0 10px; } }

.error {
  margin: 50px 0 80px; }
  .error .error__block {
    max-width: 500px;
    width: 100%;
    margin: 0 auto; }
    .error .error__block .error__list {
      text-align: center;
      position: relative; }
      .error .error__block .error__list .error__title {
        font-weight: 500;
        font-size: 48px;
        line-height: 1.3;
        text-align: center;
        color: #313131; }
      .error .error__block .error__list .error__text {
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #313131;
        margin-top: 20px; }
      .error .error__block .error__list:before {
        content: '';
        background-image: url("/src/img/svg/404-circle.svg");
        position: absolute;
        top: -20px;
        left: 50px;
        max-width: 312px;
        width: 100%;
        height: 332px;
        z-index: -1; }
    .error .error__block .error__photo {
      max-width: 255px;
      width: 100%;
      height: 307px;
      margin: 25px auto 0; }

@media (max-width: 767.98px) {
  .error {
    margin: 40px 0 50px; }
    .error .error__block .error__list {
      text-align: center; }
      .error .error__block .error__list .error__title {
        font-size: 40px; }
      .error .error__block .error__list .error__text {
        font-size: 24px;
        margin-top: 20px;
        text-align: center; }
    .error .error__block .error__photo {
      max-width: 205px;
      height: 250px;
      margin: 20px auto 0; } }

@media (max-width: 575.98px) {
  .error {
    margin: 15px 0 20px; }
    .error .error__block .error__list .error__title {
      font-size: 36px; }
    .error .error__block .error__list .error__text {
      font-size: 18px; }
    .error .error__block .error__list:before {
      display: none; }
    .error .error__block .error__photo {
      max-width: 167px;
      height: 201px;
      margin: 20px auto 0; } }

.tasks-href {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.tasks-search {
  max-width: 282px;
  width: 100%; }
  @media only screen and (max-width: 991.98px) {
    .tasks-search {
      max-width: 100%; } }
  .tasks-search .tasks-search__form {
    width: 100%; }
  .tasks-search .tasks-search__block {
    width: 100%; }
    .tasks-search .tasks-search__block .tasks-search__label {
      width: 100%;
      position: relative; }
      .tasks-search .tasks-search__block .tasks-search__label .tasks-search__inp {
        width: 100%;
        height: 37px;
        background: #F4F4F4;
        border-radius: 30px;
        font-weight: normal;
        font-size: 14px;
        color: #313131;
        padding-left: 30px; }
        .tasks-search .tasks-search__block .tasks-search__label .tasks-search__inp::placeholder {
          font-weight: normal;
          font-size: 14px;
          color: #313131; }
      .tasks-search .tasks-search__block .tasks-search__label:after {
        content: "";
        background-image: url("/src/img/svg/icon-search.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 2px;
        left: 10px;
        width: 14px;
        height: 14px; }

.tasks__text__block {
  display: none; }

.tasks__cart {
  display: flex;
  align-items: start;
  position: relative; }
  .tasks__cart .tasks__text {
    padding-left: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: #000000;
    margin-top: 5px; }
    .tasks__cart .tasks__text--detail {
      padding-top: 15px; }
    .tasks__cart .tasks__text .tasks__text__bold {
      font-weight: bold; }
  .tasks__cart .tasks__text__none {
    max-width: 890px;
    width: 100%; }
  .tasks__cart:after {
    content: "";
    background-image: url("/src/img/svg/Vector8.svg");
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    height: 263px;
    z-index: -11; }

.tasks-search__mob {
  display: none; }

.repetition {
  margin: 25px 0 130px; }
  .repetition .repetition__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.3;
    color: #000000;
    display: block;
    text-align: center; }

.repetition__slider-box {
  margin-top: 20px; }
  .repetition__slider-box .repetition__box {
    width: 100%;
    background: #FEF8E6;
    border-radius: 30px;
    padding: 50px; }
    .repetition__slider-box .repetition__box .repetition__img {
      width: 100%; }

.main-title__none {
  display: none; }

.repetition__slider__min {
  margin: 15px 0 0;
  width: 100%;
  display: flex;
  justify-content: center; }

.repetition__btn {
  width: 106px;
  height: 72px;
  background: #F5C139;
  border-radius: 30px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center; }
  .repetition__btn--prev img {
    transform: rotate(180deg); }

.repetition__btn:last-child {
  margin-left: 40px; }

.repetition__btn img {
  width: 46px;
  height: 26px; }

@media (max-width: 1399.98px) {
  .tasks__cart:after {
    right: 0; } }

@media (max-width: 1299.98px) {
  .repetition__box {
    max-width: 990px;
    margin: 0 auto; } }

@media (max-width: 1169.98px) {
  .repetition__slider .slick-prev {
    left: 35%; }
  .repetition__slider .slick-next {
    right: 35%; } }

@media (max-width: 991.98px) {
  .pages .tasks__cart:after {
    display: none; }
  .repetition__box {
    padding: 36px; }
  .repetition__slider .slick-next, .repetition__slider .slick-prev {
    width: 90px;
    height: 65px; }
  .repetition__slider .slick-prev:after {
    left: 24px;
    top: 19px; }
  .repetition__slider .slick-next:after {
    right: 25px;
    top: 19px; }
  .tasks__text__block {
    display: block;
    max-width: 700px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #000;
    margin: 0px 0 15px; }
  .tasks__text .tasks__text__bold {
    font-size: 15px; }
  .tasks-search {
    display: none; }
  .tasks-search__mob {
    display: block !important;
    margin: 5px  0; }
  .tasks__cart .pages__box {
    width: 170px;
    margin: 20px 0px; } }

@media (max-width: 767.98px) {
  .main-title__none {
    display: none; }
    .main-title__none .main-title__text {
      text-align: left; }
      .main-title__none .main-title__text:after {
        top: -22px; }
  .tasks__cart .tasks__info .tasks__text {
    padding-left: 10px;
    font-size: 16px; }
  .repetition {
    position: relative;
    margin: 50px 0 60px; }
    .repetition .repetition__title {
      font-size: 17px;
      position: absolute;
      max-width: 450px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 33px;
      z-index: 999; }
    .repetition .repetition__box {
      padding: 80px 36px 36px; }
  .repetition__btn {
    width: 66px;
    height: 45px; }
  .repetition__btn img {
    width: 27px;
    height: 15px; } }

@media (max-width: 575.98px) {
  .min-container {
    padding-right: 0px;
    padding-left: 0px; }
  .tasks__cart .pages__box {
    margin: 20px 0; }
  .repetition {
    margin: 35px 0 30px; }
    .repetition .repetition__title {
      width: 100%;
      font-size: 16px;
      max-width: 270px;
      top: 19px; }
    .repetition .repetition__box {
      padding: 75px 10px 85px; }
  .repetition__slider__min {
    margin: -65px auto 0;
    max-width: 150px; }
  .tasks__text__block {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.3; } }

@media only screen and (max-width: 1024px) {
  .tasks__cart .pages__box {
    position: absolute;
    width: 210px;
    top: 0; } }

@media only screen and (max-width: 1024px) {
  .tasks-wrap {
    position: absolute;
    top: 50px;
    left: 224px; } }

@media only screen and (max-width: 768px) {
  .tasks-wrap {
    top: 30px;
    left: 200px; } }

@media only screen and (max-width: 479px) {
  .tasks-wrap {
    top: 0;
    left: 140px; } }

@media only screen and (max-width: 1024px) {
  .tasks-wrap .main-title {
    padding-left: 10px; } }

@media only screen and (max-width: 1024px) {
  .tasks-wrap .tasks__text {
    padding-left: 0; } }

@media only screen and (max-width: 1024px) {
  .tasks__info .tasks__text:first-of-type {
    top: 140px; } }

@media only screen and (max-width: 1024px) {
  .tasks__info .tasks__text:nth-of-type(2) {
    top: 100px; } }

@media only screen and (max-width: 1024px) {
  .tasks__info .tasks__text__none {
    position: static; } }

@media only screen and (max-width: 1024px) {
  .tasks__cart {
    padding-top: 360px;
    padding-bottom: 15px; } }

@media only screen and (max-width: 568px) {
  .tasks__cart {
    padding-top: 260px; } }

@media only screen and (max-width: 479px) {
  .tasks__cart {
    padding-top: 240px; } }

@media only screen and (max-width: 768px) {
  .main-title .main-title__text {
    padding-left: 0; } }

@media only screen and (max-width: 568px) {
  .tasks__info .main-title {
    top: 30px;
    padding-left: 10px; } }

@media only screen and (max-width: 568px) {
  .tasks__cart .pages__box {
    width: 180px;
    height: 220px; } }

@media only screen and (max-width: 479px) {
  .tasks__cart .pages__box {
    width: 130px;
    height: 200px; } }

.footer {
  background: #313131; }
  .footer .footer__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0; }

.footer__link {
  display: flex;
  align-items: center; }
  .footer__link .footer__data {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.3;
    color: #F1F1F1;
    margin-right: 4px; }
  .footer__link .footer__mail {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.3;
    color: #F1F1F1; }

.footer__info {
  display: flex;
  align-items: center; }
  .footer__info .footer__info__href {
    background: #4B4A46;
    border-radius: 30px;
    font-weight: normal;
    font-size: 12px;
    color: #FFFFFF;
    padding: 8px 25px;
    margin-left: 20px; }
    .footer__info .footer__info__href:hover {
      background: #63625d; }

@media (max-width: 767.98px) {
  .footer__block {
    padding: 20px 0;
    flex-direction: column-reverse; }
    .footer__block .footer__link {
      margin-top: 10px; }
    .footer__block .footer__info .footer__info__href {
      padding: 8px 20px;
      margin-left: 5px; } }

.read-more-input {
  position: fixed;
  clip: rect(1px, 1px, 1px, 1px); }

.read-more-input ~ .read-more-content {
  max-height: 7.5em; }

.read-more-input:checked ~ .read-more-content {
  max-height: 100em; }

.read-more-input ~ .read-more-btn:after {
  content: "Развернуть"; }

.read-more-input:checked ~ .read-more-btn:after {
  content: "Свернуть"; }

.read-more-content {
  position: relative;
  overflow: hidden;
  -moz-transition: max-height 0.4s ease-in-out;
  -webkit-transition: max-height 0.4s ease-in-out;
  transition: max-height 0.4s ease-in-out;
  max-height: 100%; }

.read-more-btn {
  background-color: #f5c139;
  display: block;
  margin: 20px auto 0;
  text-align: center;
  width: 145px;
  padding: 8px 0;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
  font-size: 16px; }
