.read-more-input {
  position: fixed;
  clip: rect(1px, 1px, 1px, 1px);
}

.read-more-input ~ .read-more-content {
  max-height: 7.5em;
}

.read-more-input:checked ~ .read-more-content {
  max-height: 100em;
}

.read-more-input ~ .read-more-btn:after {
  content: "Развернуть";
}

.read-more-input:checked ~ .read-more-btn:after {
  content: "Свернуть";
}

.read-more-content {
  position: relative;
  overflow: hidden;
  -moz-transition: max-height 0.4s ease-in-out;
  -webkit-transition: max-height 0.4s ease-in-out;
  transition: max-height 0.4s ease-in-out;
  max-height: 100%;
}

.read-more-btn {
    background-color: #f5c139;
    display: block;
    margin: 20px auto 0;
    text-align: center;
    width: 145px;
    padding: 8px 0;
    color: #fff;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
}