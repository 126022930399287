.container{
	max-width: 1210px;
	width: 100%;
	margin:0 auto;
}

.accordeon{
	background: #FEF8E6;
	padding:40px;
	border-radius: 30px;
}

.accordeon__item{
	background: #FFFFFF;
  border-radius: 30px;
  // transition: 0.2s;
}

.item_even{
	background: #F9F9F9;
}
.accordeon__category{
  display: flex;
  align-items: center;
  // transition: 0.2s;
}
.accordeon__category__number{
  padding: 5px 8px;
  margin-left: 35px;
  border-radius: 30px;
  background-color: #f2a90d;
  color: #fff;
  font-size: 15px;
}

.accordeon__item:not(:last-child) { margin-bottom: 15px; }

.accordeon__item .accordeon__item { margin: 0 23px; }


.accordeon__head { 
  position: relative;
  display: flex; 
  align-items: center;
  min-height: 70px; 
  padding: 0 60px 0 23px; 
  border-radius: 15px; 
  font-size: 22px; 
  color: #000;
  cursor: pointer; 
  border-radius: 30px;
  // transition: 0.2s;
 }

.accordeon__item.active > .accordeon__head:before { 
 transform: translateY(-50%) rotate(45deg); 
 transition: 0.4s;
}
.accordeon__none__bef .accordeon__head{
  min-height: 60px; 

}
.accordeon>.active{
  padding-bottom: 15px;
}
.accordeon__none__bef .accordeon__head:before {
  display: none;
}
.accordeon__none__bef{
  margin-top: 15px;
  margin-bottom: 10px;
}
.accordeon__head:before {
	content: ""; 
	position: absolute;
    right: 30px;
    width: 10px;
    height: 10px;
    border-top: 1.5px solid #9C9C9C;
    border-left: 1.5px solid #9C9C9C;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
    transition: 0.4s;
	}

.accordeon__head:hover, .accordeon__number:hover { 
  transition: 0.4s;
  background-color: #f5c139;
	// background: linear-gradient(268.25deg, #F48C1D 0%, #F1B904 100%);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  // .accordeon__category__number {
  //   background-color: #f48d1c;
  //   color: #fff;
  // }
}
.accordeon__head:hover{
  color: #fff;
  transition: 0.2s;

}
 .accordeon__number:hover{
  color: #fff;
  // transition: 0.2s;


 }
.accordeon__main { 
	display: none; 
	padding-top: 15px;
	padding-bottom: 15px;
}

.accordeon__list { 
	display: flex; 
	flex-wrap: wrap;
	margin: -10px -5px 0; 
	padding-bottom: 15px;
	list-style: none;
  margin-left:20px;
  margin-right:20px;
}


.accordeon__list li { 
	
	margin: 10px 5px 0; 
}

.accordeon__list--row .accordeon__li { 
	width: 100%; 
	flex: 100%;
}

.accordeon__list--row .accordeon__number {
  justify-content: flex-start;
  height: auto;
   padding: 20px;
}

.accordeon__number {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F9F9F9;
    border-radius: 15px;
    width: 60px;
    height: 60px; 
    font-size: 20px;
    font-weight: 500;
    color: #000;
    transition: 0.4s;
    text-decoration: none;
}
.accordeon__list--row .accordeon__number { 
	justify-content: flex-start; 
  width: max-content;
	height: auto; 
  width: auto;
	padding: 20px; 
}

.accordeon__list--row .accordeon__li {
 	width: 100%; 
 	flex: 100%;
 }

@media (max-width: 575.98px) {
  .accordeon {
    padding: 20px 15px;
  }
.accordeon__head {
    padding: 0 40px 0 19px;
    font-size: 16px;
    min-height: 50px;
}
.accordeon__item .accordeon__item { margin: 0 15px; }

   
   .accordeon__list--row .accordeon__number {
       padding: 15px;
      font-size: 19px;
    }
    .accordeon__head:before {
      right: 20px;
  }
  .accordeon__list {
    margin: -10px 5px 0;
}
.accordeon__list li {
    margin: 10px 4px 0;
}
.accordeon__number {
 
    border-radius: 10px;
    width: 35px;
    height: 35px;
    font-size: 15px;
   
}

}