.mfp-content {
    top: 82px;
}
.pages__min__height{
    height: 100vh;
}

.pages{
    position: relative;
    .pages__main{
        margin-top: 20px;
    }
    .women-fon{
        position: absolute;
        right: 0;
        top: -75px;
        max-width: 331px;
        width: 100%;
        height: 624px;
        z-index: -1; 
    }
  
    .img-book{
        position: absolute;
        left: 0;
        bottom: 450px;
        width: 100px;
        height: 137px;
        z-index: -1; 
    }
    .img-book-top{
        bottom: auto;
        top: 400px;

    }
    .img-book__right{
        right: 0;
        position: absolute;
        bottom: 450px;
        width: 125px;
        height: 137px;
        z-index: -1;
    }
    .img-circle{
        position: absolute;
        right: 0;
        bottom: 350px;
        width: 110px;
        height: 137px;
        z-index: -1; 
    }
    .img-circle__left{
        position: absolute;
        left: 0;
        bottom: 750px;
        width: 120px;
        height: 137px;
        z-index: -1;
    }
    .women-fon-tow {
        position: absolute;
        left: 0;
        bottom: -48px;
        max-width: 246px;
        width: 100%;
        height: 528px;
        z-index: -1;
    }

}
.pages__cnt{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
}




@media (max-width: 1499.98px){
    .max-container {
        max-width: 1330px;
    }
}
@media (max-width: 1399.98px){
    .max-container {
        max-width: 1200px;
    }
    .pages__cnt{
        margin-top: 40px;
    }
    .women-fon {
        max-width: 299px;
        height: 562px;
    }

}
@media (max-width: 1299.98px){
     .max-container {
        padding: 0 25px;
    }
    .pages{
        .img-book{
            display: none;
        }
        .img-book-top{
            display: block;
        }
        .tasks__fon{
            top: 160px;
        }
        .tasks__cart:after {
            bottom: 45px;
            right: 0;
        }
    }
   .women-fon{
        display: none!important;
    }

}
@media (max-width: 1200.98px){

}
@media (max-width: 1169.98px){

    .women-fon-tow {
        display: none;
    }


}
@media (max-width: 1024.98px){
    .pages__main {
        .pages__cnt{
            flex-direction: column;
            align-items: center;
        }
    }
    .mob-remove{
        display: none;
    }
}
@media (max-width: 991.98px){

    .women-fon {
        max-width: 252px;
        height: 470px;
        top: 74px;
    }

}
@media (max-width: 767.98px){
    .pages{
        .women-fon{
            display: none;
        }
       

        .img-circle{
            display: none;
        }
        .img-book__right{
            display: none;
        }
        .img-book-top{
            display: none;
        }
        .img-circle__left{
            display: none;
        }
    } 


}
@media (max-width: 575.98px){
    .max-container {
        padding: 0 10px;
    }
}

