.error{
  margin: 50px 0 80px;
    .error__block{
      max-width: 500px;
      width: 100%;
      margin: 0 auto;
      .error__list{
        text-align: center;
        position: relative;
        .error__title{
          font-weight: 500;
          font-size: 48px;
          line-height: 1.3;
          text-align: center;
          color: #313131;
        }
        .error__text{
          font-weight: normal;
          font-size: 36px;
          line-height: 42px;
          text-align: center;
          color: #313131;
          margin-top: 20px;
        }
        &:before{
          content: '';
          background-image: url('/src/img/svg/404-circle.svg');
          position: absolute;
          top: -20px;
          left: 50px;
          max-width: 312px;
          width: 100%;
          height: 332px;
          z-index: -1;
        }
      }
      .error__photo{
        max-width: 255px;
        width: 100%;
        height: 307px;
        margin: 25px auto 0;
      }
    }

}


@media (max-width: 767.98px){
  .error{
    margin: 40px 0 50px;
    .error__block{
      .error__list{
        text-align: center;
        .error__title{
          font-size: 40px;
        }
        .error__text{
          font-size: 24px;
          margin-top: 20px;
          text-align: center;
        }
      }
      .error__photo{
        max-width: 205px;
        height: 250px;
        margin: 20px auto 0;
      }
    }

  }
}


@media (max-width: 575.98px){
  .error {
    margin: 15px 0 20px;

    .error__block {
      .error__list {
        .error__title {
          font-size: 36px;
        }

        .error__text {
          font-size: 18px;
        }
        &:before {
         display: none;
        }
      }
      .error__photo {
        max-width: 167px;
        height: 201px;
        margin: 20px auto 0;
      }
    }
  }
}