
  .sidebar-nav__block{
    max-width: 353px;
    width: 100%;
    background: #F5C139;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    .sidebar-nav__title{
      font-weight: 600;
      font-size: 25px;
      line-height: 1.3;
      color: #FFFFFF;
      text-align: center;
      padding: 10px 25px;
    }
    .sidebar-nav__list{
      margin-top: 15px;
      .sidebar-nav__dropdown__item{
        .sidebar-nav__dropdown__link{
          display: flex;
          align-items: center;
          padding: 10px 25px 10px 30px;
          transition: 0.4s;
          .flag{
            width: 26px;
            margin-right: 15px;
          }
          .sidebar-nav__dropdown__text{
            font-weight: 500;
            font-size: 20px;
            line-height: 1.2;
            color: #FFFFFF;
          }
          &:hover{
            transition: 0.4s;
            background: rgba(255, 255, 255, 0.15);
          }
        }
      }
    }

  .sidebar-nav__big{
    max-width: 100%!important;
  }

}

  @media (max-width: 1399.98px){
      .finished-min__block{
            max-width: 300px;
          }
  }
  @media (max-width: 1299.98px){

        .sidebar-nav__block{
          max-width: 270px;
          .sidebar-nav__title {
            padding: 10px 20px;
          }
        }
        .sidebar-nav__list{
          .sidebar-nav__dropdown__link{
            padding: 10px 20px;
           
          }
        }

  }
  @media (max-width: 991.98px){

  .women-fon{
    display: none;
  }
}
  @media (max-width: 1024.98px){
    .sidebar-nav__block{
      position: relative;
      max-width: 353px;
      padding-top: 0px;
      padding-bottom: 0px;
      .sidebar-nav__title {
        font-size: 22px;
        padding: 20px 25px;
        width: 100%;
        display: block;
        text-align: left;
      }
      .sidebar-nav__list{
        width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        z-index: 99;
        padding: 15px 0 20px;
        background: #F5C139;
        border-radius: 10px 10px 30px 30px;
      }
      &:after{
        content: "";
        width: 10px;
        height: 10px;
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        -webkit-transform: translateY(-50%) rotate(-135deg);
        transform: translateY(-50%) rotate(-135deg);
        position: absolute;
        top: 50%;
        right: 20px;
        transition: 0.3s;
      }
    
      
     
    }
    .sidebar__item {
        border-radius: 30px 30px 0px 0px;
      .sidebar-nav__list{
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        top: 50%;
      }
       &:after{
        transition: 0.3s;
        transform:  rotate(45deg);
      } 
    }

  }

  @media (max-width: 575.98px){

    .sidebar-nav__block{
      position: relative;
      max-width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      .sidebar-nav__title {
        font-size: 18px;
        padding: 10px 20px;
      }
      .sidebar-nav__list{
        .sidebar-nav__dropdown{
          .sidebar-nav__dropdown__item{
            .sidebar-nav__dropdown__link{
              padding: 10px 20px;
              .sidebar-nav__dropdown__text{
                font-size: 16px!important;
              }
            }
          }
        }
      }

    }


  }