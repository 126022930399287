
.home-page{
  background: #FEF8E6;
  border-radius: 30px;
  padding: 80px 50px 50px;
  margin-top: 50px;
  margin-bottom: 80px;
  position: relative;
  .home-page__title{
    position: absolute;
    top: 40px;
    left: 310px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #000000;
  }
  .cs-link-icon{
    width: 20px;
  }
}

.home-page__min {

  .cell__class-number {
    display: none;
  }
  .home__link {
    position: relative;
    width: 94px;
    height: 70px;
    text-decoration: none;
    background-color: #fff;
    font-size: 20px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home__href__hide{
    pointer-events: none;
  }
  .home-page__number{
    display: flex;
    justify-content: space-between;
    .home__link{
      border-radius: 30px 30px 0 0;
      background-color: #fbfbfb;
    }
    .home__link:nth-child(odd){
      background-color: #FFFFFF;
    }
    .home__link:hover{
          background-color: #f3f3f3;
    }
    .empty__items{
      padding-left: 20px;

    }
    .page__empty{
      min-width: 250px;
      background-color: #fef8e6!important;
      cursor: default;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.3;
      padding-left: 20px;
      color: #000000;
      .home__href{
        border-radius: 30px 30px 0  0;
      }

    }
  }
  .hover {
    background-color: rgba(253, 202, 85, 0.36)!important;
  }
  .hover:hover {
    background-color: rgb(253, 202, 85)!important;
  }
  .home-page__item:nth-child(2) .home__link:first-child {
    border-radius: 30px 0 0;
  }
  .home-page__item .home__link:first-child {
    max-width: 250px;
    width: 100%;
    padding-left: 20px;
    justify-content: flex-start;
  }
 
  .home-page__item:last-child  .home__link:first-child{
    border-radius: 0 0 0 30px;
  }
  .home-page__item:last-child  .home__link:last-child{
    border-radius: 0 0 30px 0;
  }
   .home-page__box {
     display: flex;
     justify-content: space-between;
     .home__link{
       .s-link {
        height: 100%;
        min-width: 250px;
        max-width: 250px;
        background-color: #fff;
        padding: 0 15px 0 35px;
        -webkit-box-pack: start;
        justify-content: flex-start;
      }
       .s-link__name{
         font-weight: normal;
         font-size: 18px;
         line-height: 1.3;
         color: #000000;
         margin-left: 10px;
        }
       .flag{
         width: 26px;
         height: 18px;
       }
    }
     .home__availability:nth-child(2n) {
       background-color: #fbfbfb;
     }
     .is-active:hover {
       background-color: #f3f3f3;
     }
   }
}
@media (max-width: 1399.98px){
  .home-page{

    margin: 50px auto 80px;
  }
}
@media (max-width: 1299.98px){
 
  .home-paes__img{
    top: -79px;
    max-width: 242px;
    height: 522px;
  }
}


@media (max-width: 1169.98px){
  .home-paes__img{
    display: none;
  }
  .home-page{
    padding: 70px 35px 40px;
    margin-top: 45px;
    margin-bottom: 60px;
    .home-page__title{
      top: 40px;
      left: 280px;
      font-size: 20px;
    }
  }
  .home-page__min {
    .home-page__box {
      .home__link {
        .s-link__name {
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
    .home__link {
      width: 80px;
      height: 60px;
      font-size: 18px;
      .flag{
        width: 20px;
        height: 15px;
      }
    }
    .home-page__item {
        .home__link:first-child {
          max-width: 200px;
          padding-left: 15px;
        }
    }

    .home-page__number {
      .empty__items {
        padding-left: 15px;
      }
      .page__empty {
        min-width: 200px;
        font-size: 18px;
        padding-left: 15px;
      }
    }
  }
}

@media (max-width: 991.98px){
.home-page__min .home-page__box .home__availability:nth-child(2n) {
    background-color: #F5C139;
}
.active__down{
  display: none!important;

}
.active__arrow {
  border-radius: 20px!important;
   &:after {
    transform: translateY(-50%) rotate(-135deg)!important;
  }
}
  .home-page {
    padding: 20px 5px 30px;
    margin: 40px auto;
    max-width: 100%;
  }
  .home-page__title{
    display: none;
  }
  .home-page__min {
    .home-page__item:nth-child(2) .home__link:first-child {
      border-radius: 20px 20px 0 0;
    }
    .page__empty {
      min-width: auto!important;
    }
    .hover {
      background-color: #f5c139!important;
    }
     .hover:hover {
      background-color:  #FFD25C!important;
    }
    .home-page__box {
      margin-top: 10px;
      flex-wrap: wrap;
      justify-content: center;

      .is-active {
        background-color: #ffd25c!important;
      }
      .home__availability{
        background-color: #F5C139;
        &:not(.is-active) {
          pointer-events: none;
        }
      }
      .home__availability:nth-child(2) {
        border-radius: 0 0 0 20px !important;
      }

      .home__availability:last-child {
        border-radius: 0 0 20px 0 !important;
      }
    }
    .home__link {
      max-width: 9%;
      width: 100%;
      height: 35px;
      font-size: 15px;
      background: #F5C139;
      text-align: center;
      padding-top: 3px;
      transition: 0.4s;

    }
    .home-page__box{
      transition: 0.4s;

    }
    .home__href {
      display: none;

    }

    .home-page__item {
      .home__link:first-child {
        display: block;
        max-width: 100%;
        margin: 0 2.5px;
        border-radius: 20px 20px 0 0;
        position: relative;
        padding: 10px 15px;
        height: auto;
        text-align: left;
        background: #FFFFFF!important;
      }

      .cs-link-icon {
        display: none;
      }
        .flag {
          width: 19px;
          height: 14px;
        }
      }
    }
    .is-active {
      .cell__class-number {
        color: #FFFFFF!important;
      }
    }
    .home__availability{
       .cell__class-number {
        display: block;
        font-weight: normal;
        font-size: 17px;
        line-height: 1.2;
        color: #FFFFFF;
      }
    }
    .home-page__number {
      .page__empty {
        min-width: 100%;
        font-size: 18px;
        padding-left: 0;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  .is-active:hover {
    background: #FFD25C!important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  }
  .home-page__min .home-page__item:last-child .home__link:first-child {
      border-radius: 20px 20px 0 0;
  }
}


@media only screen and (max-width: 479px) {
  .home-page__min .home-page__number .page__empty {
    padding: 0;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    span {
      font-weight: 700;
    }
  }
}

.pages__cart .cart__big .cart__bitwin {
  .pages__content {
  }
}

.cart__bitwin .pages__content {
  @media only screen and (max-width: 991.98px) {
    max-width: 75%;
    justify-content: center;
  }
}