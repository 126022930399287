.footer{
	background: #313131;
	.footer__block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 30px 0;
	}
}

.footer__link{
	display: flex;
	align-items: center;
	.footer__data{
		font-weight: normal;
		font-size: 18px;
		line-height: 1.3;
		color: #F1F1F1;
		margin-right: 4px;
	}
	.footer__mail{
		font-weight: normal;
		font-size: 18px;
		line-height: 1.3;
		color: #F1F1F1;
	}
}
.footer__info{
	display: flex;
	align-items: center;
	.footer__info__href{
		background: #4B4A46;
		border-radius: 30px;
		font-weight: normal;
		font-size: 12px;
		color: #FFFFFF;
		padding: 8px 25px;
		margin-left: 20px;
		&:hover{
			background: #63625d;
		}
	}
}

@media (max-width: 767.98px){
	.footer__block{
		padding: 20px 0;
		flex-direction: column-reverse;
		.footer__link{
			margin-top: 10px;
		}
		.footer__info{
			.footer__info__href{
				 padding: 8px 20px;
				margin-left: 5px;
			}
		}
	}
}
